.about-section{
    
    margin-top: 7.5rem;
    text-align: center;
    
}

.centrist{
    
    background: rgba(233, 222, 255, 0.35);
    /*margin-left: 7.5rem;
    margin-right: 7.5rem;*/
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    border-radius: 1.5rem;
    padding: 1rem;
    text-align: center;
  
}

.c1{
    text-align: center;
    padding: 0.7rem;
}


@media screen and (max-width:700px) {

    .centrist{
        /**/
    }
}