.th{
    background-color: #F7F3FF;
    padding-left: 5%;
    padding-right: 5%;
    padding: 5%;
    margin-top: 3rem;
    border-radius: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 85%;
}

.imgg{ 
    padding-left: 0%;
    padding-right: 0%;
    margin-left: 5%;
    margin-right: 5%;
    width: 15%;
    height: 22.5vh;
}

.th .h1{
    margin-left: auto;
    margin-right: auto;
    padding-left: 0%;
    padding-right: 0%;
    font-family: Helvetica;
}

.space{
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 0%;
    margin-right: 0%;
}

.thFont{
    font-size: 3rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width:850px) {
    .th{
        flex-direction: column;
    }
    .imgg{
        width: 8.5rem;
    }
}
