.marquee {
    margin: 2rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

}

img {
    object-fit: contain;
    height: auto;
    width: 12rem;
    padding: 2.5rem;
}

img:hover {
    cursor: pointer;
}

.sp2{
    display: flex;

}

.sp1{
    display: flex;
}

.robo{
    width: 18rem;
}

.bluelearn{
    margin-left: 2.5rem;
}

@media screen and (max-width:850px){
    .sp1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sp2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img{
        width: 12rem;
    }

    .robo{
        width:15rem
    }

    .bluelearn{
        margin-left: 0;
    }

    
}