.contact{
    margin: 0.5rem 0;
    padding: 0.5rem;
    text-align: center;
}
.contact h1{
    font-weight: 700;
    text-align: center;
    margin-top:0;
}

.styling{
    margin: 0.25rem auto 1rem auto;
    text-align: center;
}

.contact-one {
    display: flex;
    flex-direction: column;
    
}