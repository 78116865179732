.hero-section{
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 2rem 0.5rem;
    flex-shrink: 0;
    background: rgba(198, 171, 255, 0.39);
    margin-top: 2rem;
   /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  -webkit-box-shadow:0px 0px 100px 100px Chartreuse;
  -moz-box-shadow:0px 0px 100px 100px yellow;*/
    box-shadow:0px 0px 3rem 3rem rgba(198, 171, 255, 0.39);
    border: 0;
    max-width: 100vw;
}

.hero-button{
    margin-top: 7rem;
    font-weight: 600;
    color: white;
    background:linear-gradient(0deg, rgba(255, 0, 0, 0.66), rgba(255, 0, 0, 0.66));
    padding: 0.5em 1.5em;
    font-size: 1.5rem;
    border: 1.44px solid black;
    border-radius: 2rem;
    flex-grow:  0;
    flex-shrink: 0;
    flex-basis: 0;
    width: fit-content;
    cursor: pointer;
    border: 2px solid black;
    align-self: flex-start;
    text-decoration: none;
}

.hero-button:hover{
    color: white;
    background: black;
    
}

.hero-h1{
    line-height: 3.5rem;
    margin-bottom: 0rem;
    margin-right: 1rem;
    margin: 0;
    color: #730075;
    font-weight: 800;
    font-size: 2.5rem;
    max-width: 70%;
   /* border: 2px solid black;*/
    justify-self: flex-start;
    text-align: left;
}

.section-one{
    display: flex;
    flex-direction: column;
   /* border: 2px solid black;*/
    padding: 1rem 1rem;
    margin: 0;
    align-self: center;
    justify-content: space-around;
}

.sec-two{
   /* background:  #C6ABFF63;*/
    margin: 0;
    align-self: center;
}

.Groupp{
    height: 17rem;
    width: 22rem;
}

@media screen and (max-width:700px) {

    .hero-section{
        flex-direction: column;
        justify-content: centre;
        align-items: center;
    }

    .hero-h1{
        justify-self: center;
        align-self: center;
        text-align: center;
    }

    .hero-button{
        align-self: center;
        text-align: center;
        justify-self: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}