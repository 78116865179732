.overlaycard{
position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
 /* visibility: hidden;*/
  opacity: 0;
  transition: all 1s ease-out;
    border-radius: 1.5rem;
    }

.overlaycard:hover{
    visibility: visible;
    opacity: 1;
    transition: all 0.25s ease-in;
 }