.registration{
    margin:0;
    padding:0;
}

.registration h1{
    border-bottom: 1.17px solid black;
    padding: 1rem 5%;
    margin: 1rem 5%;
    margin-bottom: 0;
    text-align: center;
    color: #730075;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.8rem;
    letter-spacing: 0em;
    position: relative;
}

.registration h1 button{
    background-color: whitesmoke;
    color:  black;
    border-radius: 50%;
    border: 1px solid black;
    position: absolute;
    padding: 0rem 1rem;
    right: 1rem;
    bottom: 0.5rem;
    font-size: 1.2rem;
}

.registration-container{
    display: flex;
    flex-direction: row;
    padding: 5%;
    margin: 1rem;
    justify-content: space-between;
    margin-left: 0;
}

.registration-container div{
    width: 43rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0rem;
}

.registration-container input{
    margin-bottom: 2rem;
    padding: 1.1rem;
    font-size: 1.2rem;
    border: 1px solid black;
    border-radius: 1.1rem;
    font-family: 'Inter', sans-serif;
}
.registration-container textarea{
    margin-bottom: 2rem;
    padding: 1.1rem;
    font-size: 1.2rem;
    border: 1px solid black;
    border-radius: 1.1rem;
    font-family: 'Inter', sans-serif;
    resize: none;
}

.registration button{
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: 4.68px solid #C3B4FF;
    padding: 0.5rem 3.5rem;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.8rem;
    letter-spacing: 0em;
    text-align: center;
    background: none;
    border-radius: 2rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.registration button:hover{
    background-color: #C3B4FF;
    cursor: pointer;
    color: white
}

.gender{
    height: 6.4rem;
    border-radius: 1rem;
    font-size: 1.1rem;
    font-family: sans-serif;
    padding: 0.8rem;
    border: 1px solid black;
}

@media screen and (max-width: 900px) {
     .registration-container{
      flex-direction: column;
      align-items: center;
      margin-top: 4rem;
      
    }
    .registration-container div{
        width: 90%;
        padding-bottom: 5rem;
        margin-left: 0rem;
        margin-top: -2rem;
        width: 20rem;
        
        
    }

  


}

