.whoapply{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    max-width: 90%;
}

.empty{
    background: rgba(198, 171, 255, 0.39);
    background: #191E51;
    color: whitesmoke;
    padding: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1.5rem;
    width: fit-content;
    max-width: 90%;
}


