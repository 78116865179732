.aishiteru{
    background-color: #191E51;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Inter;
    padding-top:0rem;
    font-size: 1.2rem;
    max-width: 100vw;
}

.aishiteru a{
    text-decoration: none;
    color: lightblue;
    padding-left: 0.75rem;
}
