.basic{
    
    display: flex;
    flex-direction: row;
    padding-top: 1.5rem;
    justify-content: center;

}

.college{   
  margin-left: 5rem;
  margin-right: 60rem;

}

.Profiles{
    padding-top: 0.0rem;
    
    margin-left: -4.35rem; 

    position: absolute;
    
}

.pts{
    margin-left: 70rem;
   position: absolute;
    
}

.rank{
    margin-left: 0rem;
    
}

.ptset{
    position: absolute;
    margin-left: 0rem;
    
}

.finally{
   margin-top: 3.48rem;
   margin-right: 0;
   font-weight: 500;
}

.finally2{
    margin-top: 3.48rem;
    margin-right: 0rem;
    margin-left: -0.3rem;
    font-weight: 500;
    z-index: -10;
 }

 .betterwork{
    margin-top: 3.48rem;
    margin-right: 0;
    font-weight: 700;
    color: #730075;
 }

.move1{
    margin-left: 1.15rem;
    z-index: 2;
}

.move2{
    z-index: 4;
    margin-right: 1rem;
}


h1, b{
    color: #730075;
}

